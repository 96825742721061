import * as React from "react";

function PreviewIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5283 0.207581C12.7787 0.207581 13.7924 1.22128 13.7924 2.47173V11.5283C13.7924 12.7788 12.7787 13.7925 11.5283 13.7925H2.47167C1.22121 13.7925 0.20752 12.7788 0.20752 11.5283V2.47173C0.20752 1.22128 1.22121 0.207581 2.47167 0.207581H11.5283ZM9.05644 7.24302C8.93296 7.14719 8.75517 7.16961 8.65934 7.29309L6.24526 10.4038L4.58342 8.40296C4.57035 8.38722 4.55561 8.37295 4.53946 8.36039C4.41608 8.26442 4.23827 8.28665 4.1423 8.41003L2.07222 11.0716C2.03358 11.1212 2.01261 11.1824 2.01261 11.2453C2.01261 11.4016 2.13932 11.5283 2.29563 11.5283H11.717C11.7782 11.5283 11.8378 11.5085 11.8868 11.4717C12.0118 11.3779 12.0372 11.2006 11.9434 11.0755L9.10934 7.2968C9.09418 7.27659 9.0764 7.25851 9.05644 7.24302Z"
        fill="#99A2AD"
      />
    </svg>
  );
}

export default PreviewIcon