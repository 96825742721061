import * as React from "react";

function DaDIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="10"
      viewBox="0 0 18 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 8C17.5523 8 18 8.44772 18 9C18 9.55228 17.5523 10 17 10H1C0.447715 10 0 9.55228 0 9C0 8.44772 0.447715 8 1 8H17ZM17 4C17.5523 4 18 4.44772 18 5C18 5.55228 17.5523 6 17 6H1C0.447715 6 0 5.55228 0 5C0 4.44772 0.447715 4 1 4H17ZM17 0C17.5523 0 18 0.447715 18 1C18 1.55228 17.5523 2 17 2H1C0.447715 2 0 1.55228 0 1C0 0.447715 0.447715 0 1 0H17Z"
        fill="#B8C1CC"
      />
    </svg>
  );
}

export default DaDIcon