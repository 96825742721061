import * as React from "react";

function SuccessIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="42"
      height="43"
      viewBox="0 0 42 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.117188" width="42" height="42" rx="21" fill="#4BB34B" />
      <path
        d="M18.5454 24.8692L14.2148 20.5386C13.8953 20.2191 13.3773 20.2191 13.0578 20.5386C12.7382 20.8581 12.7382 21.3762 13.0578 21.6957L17.9668 26.6048C18.2864 26.9243 18.8044 26.9243 19.1239 26.6048L28.9421 16.7866C29.2616 16.4671 29.2616 15.9491 28.9421 15.6295C28.6226 15.31 28.1045 15.31 27.785 15.6295L18.5454 24.8692Z"
        fill="white"
      />
    </svg>
  );
}

export default SuccessIcon;
