import React, { useState } from "react";
import styled from "styled-components";
import { LOGIN, PASSWORD } from '../constants'

const AuthForm = () => {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");

  const sendForm = () => {
    if (login !== LOGIN)  {
        alert('Неверный логин')
        return
    };

    if (password !== PASSWORD) {
        alert('Неверный пароль')
        return
    }

    localStorage.setItem('auth', 'true');
    window.location.reload()
  };

  const onKeyDown = (e: any) => {
    if (e.keyCode == 13) {
        sendForm()
    }
  };
  
  return (
    <Form onKeyDown={(e) => onKeyDown(e)}>
      <FormTitle>Вход</FormTitle>
      <FormInputBlock>
        <FormInputLabel htmlFor="login">Login</FormInputLabel>
        <FormInput
          id="login"
          placeholder="login"
          value={login}
          onChange={(e) => setLogin(e.target.value)}
        />
      </FormInputBlock>
      <FormInputBlock>
        <FormInputLabel htmlFor="pass">Password</FormInputLabel>
        <FormInput
          id="pass"
          placeholder="password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </FormInputBlock>
      <SendForm onClick={() => sendForm()}>Войти</SendForm>
    </Form>
  );
};

export default AuthForm;

const Form = styled.div`
  width: 100%;
  max-width: 420px;
  background: #fff;
  border-radius: 10px;
  margin: 30px auto 30px;
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const FormTitle = styled.div`
  font-weight: 500;
  font-size: 23px;
  line-height: 28px;
  color: rgb(0, 0, 0);
  margin-bottom: 20px;
`;

const FormInputBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

const FormInputLabel = styled.label`
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: rgb(129, 140, 153);
`;

const FormInput = styled.input`
  outline: none;
  padding: 8px 12px;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.007em;
  background: rgb(242, 243, 245);
  border: 0.5px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  outline: none;
  width: 100%;

  &:focus {
    border: 0.5px solid #2688eb;
  }
`;

const SendForm = styled.div`
  padding: 8px 12px;
  color: #fff;
  background: #2688eb;
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 8px;
  text-align: center;

  &:hover {
    background: #115eac;
  }
`;
