import * as React from "react";

function ErrorIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 42C32.598 42 42 32.598 42 21C42 9.40202 32.598 0 21 0C9.40202 0 0 9.40202 0 21C0 32.598 9.40202 42 21 42Z"
        fill="#E64646"
      />
      <path
        d="M13.3105 13.3106L28.6894 28.6894M13.3105 28.6894L28.6894 13.3106"
        stroke="white"
        strokeWidth="1.43182"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default ErrorIcon;
