import * as React from "react";

function LoadIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="42"
      height="43"
      viewBox="0 0 42 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.117188" width="42" height="42" rx="21" fill="white" />
      <path
        d="M21 33.1172C20.5481 33.1172 20.1818 32.7509 20.1818 32.299C20.1818 31.8471 20.5481 31.4808 21 31.4808C26.7237 31.4808 31.3636 26.8409 31.3636 21.1172C31.3636 15.3935 26.7237 10.7536 21 10.7536C15.2763 10.7536 10.6364 15.3935 10.6364 21.1172C10.6364 22.8404 11.0567 24.5011 11.8494 25.9871C12.062 26.3858 11.9112 26.8814 11.5125 27.0941C11.1138 27.3068 10.6182 27.156 10.4055 26.7573C9.48725 25.0357 9 23.1107 9 21.1172C9 14.4898 14.3726 9.11719 21 9.11719C27.6274 9.11719 33 14.4898 33 21.1172C33 27.7446 27.6274 33.1172 21 33.1172Z"
        fill="#818C99"
      />
    </svg>
  );
}

export default LoadIcon;
