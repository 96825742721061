import * as React from "react";

function DropdownIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="12"
      height="6"
      viewBox="0 0 12 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.167091 0.278126C0.427714 -0.0438191 0.899978 -0.0935312 1.22192 0.167091L6.00002 4.03508L10.7781 0.167091C11.1001 -0.0935312 11.5723 -0.0438191 11.833 0.278126C12.0936 0.600072 12.0439 1.07234 11.7219 1.33296L6.47192 5.58296C6.19676 5.80571 5.80329 5.80571 5.52813 5.58296L0.278126 1.33296C-0.0438191 1.07234 -0.0935312 0.600072 0.167091 0.278126Z"
        fill="#2688EB"
      />
    </svg>
  );
}

export default DropdownIcon