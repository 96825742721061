import * as React from "react";

function AddImgIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 0.5C16.6569 0.5 18 1.84315 18 3.5V15.5C18 17.1569 16.6569 18.5 15 18.5H3C1.34315 18.5 0 17.1569 0 15.5V3.5C0 1.84315 1.34315 0.5 3 0.5H15ZM11.7248 9.82196C11.5612 9.69498 11.3256 9.72469 11.1987 9.8883L8 14.01L5.79807 11.3589C5.78075 11.338 5.76122 11.3191 5.73982 11.3025C5.57634 11.1753 5.34074 11.2048 5.21359 11.3682L2.47073 14.8948C2.41954 14.9606 2.39174 15.0416 2.39174 15.125C2.39174 15.3321 2.55963 15.5 2.76674 15.5H15.25C15.3311 15.5 15.4101 15.4737 15.475 15.425C15.6407 15.3007 15.6743 15.0657 15.55 14.9L11.7949 9.89321C11.7748 9.86644 11.7513 9.84248 11.7248 9.82196Z"
        fill="#2688EB"
      />
    </svg>
  );
}

export default AddImgIcon;
