import React from "react";
import styled from "styled-components";
import { Reorder } from "framer-motion";
import { LoanItem } from "./LoanItem";
import AdminAddBtn from "./AdminAddBtn";
import { loan } from "src/models/loan";

interface props {
  loanlist: Array<loan>,
  setLoanList: any,
}

function AdminLoanList({ setLoanList, loanlist } : props) {

  const showHandler: Function = (id: number) => {
    const newArr = loanlist.map((item) => {
        if (item.id === id) item.show = !item.show;
        return item
    })

    setLoanList(newArr)
  };

  const mainHandler: Function = (flag: string, id: number, value: string | number) => {
    const newArr = loanlist.map((item: any) => {
      if (item.id === id) item[flag] = value;
      return item
    })
    setLoanList(newArr)
  }

  const addNewLoan: Function = () => {
    const newLoan: loan = {
      id: randomInteger(1, 10000),
      show: true,
      name: '',
      offerLink: '',
      logoLink: '',
      benefits: '',
      percentMin: null,
      percentMax: null,
      sumMin: null,
      sumMax: null,
      deadline: null,
      backingColor: '#FF3347',
      backingLogoColor: null,
      order: loanlist.length,
      img: null
    };

    setLoanList([...loanlist, newLoan])
  }

  const removeLoan: Function = (id: number) => {
    setLoanList(loanlist.filter(obj => obj.id !== id))
  }

  const copyLoan: Function = (id: number) => {
    let copyItem = Object.assign({}, loanlist.find(obj => obj.id === id));

    if (copyItem) {
      copyItem.id = randomInteger(1, 10000);
      setLoanList([...loanlist, copyItem])
    }
  }

  function randomInteger(min:number, max:number) {
    let rand = min - 0.5 + Math.random() * (max - min + 1);
    return Math.round(rand);
  }

  return (
    <>
      <LoanList axis="y" onReorder={setLoanList} values={loanlist}>
        {loanlist.map((item) => {
          return <LoanItem key={item.id} item={item} showHandler={showHandler} mainHandler={mainHandler} removeLoan={removeLoan} copyLoan={copyLoan} />;
        })}
      </LoanList>
      <AdminAddBtn addNewLoan={addNewLoan} />
    </>
  );
}

export default AdminLoanList;

const LoanList = styled(Reorder.Group)`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  gap: 15px;
`;
