import * as React from "react";

function AddIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 0C9.05228 0 9.5 0.447716 9.5 1V7.00001L15.5 7C16.0523 7 16.5 7.44772 16.5 8C16.5 8.55228 16.0523 9 15.5 9L9.5 9.00001L9.50001 15C9.50001 15.5523 9.05229 16 8.5 16C7.94772 16 7.5 15.5523 7.5 15L7.499 9.00001L1.5 9C0.947715 9 0.5 8.55228 0.5 8C0.5 7.44772 0.947715 7 1.5 7L7.499 7.00001L7.49999 1C7.49999 0.447716 7.94771 0 8.5 0Z"
        fill="#2688EB"
      />
    </svg>
  );
}

export default AddIcon;
