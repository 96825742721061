import * as React from "react";

function ShowActiveIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 10C13 11.6569 11.6569 13 10 13C8.34315 13 7 11.6569 7 10C7 8.34315 8.34315 7 10 7C11.6569 7 13 8.34315 13 10ZM11.5 10C11.5 10.8284 10.8284 11.5 10 11.5C9.17157 11.5 8.5 10.8284 8.5 10C8.5 9.17157 9.17157 8.5 10 8.5C10.8284 8.5 11.5 9.17157 11.5 10Z"
        fill="#2688EB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 10C19 13.5 14 16 10 16C6 16 1 13.5 1 10C1 6.5 6 4 10 4C14 4 19 6.5 19 10ZM17.5 10C17.5 10.9827 16.7806 12.0838 15.2215 13.0303C13.7054 13.9508 11.7245 14.5 10 14.5C8.27549 14.5 6.29462 13.9508 4.77847 13.0303C3.21944 12.0838 2.5 10.9827 2.5 10C2.5 9.01731 3.21944 7.91623 4.77847 6.96968C6.29462 6.04916 8.27549 5.5 10 5.5C11.7245 5.5 13.7054 6.04916 15.2215 6.96968C16.7806 7.91623 17.5 9.01731 17.5 10Z"
        fill="#2688EB"
      />
    </svg>
  );
}

export default ShowActiveIcon