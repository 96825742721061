import * as React from "react";

function CheckBoxIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16.6958C12.4183 16.6958 16 13.1141 16 8.6958C16 4.27752 12.4183 0.695801 8 0.695801C3.58172 0.695801 0 4.27752 0 8.6958C0 13.1141 3.58172 16.6958 8 16.6958ZM12.3738 7.06957C12.7643 6.67905 12.7643 6.04588 12.3738 5.65536C11.9832 5.26484 11.3501 5.26484 10.9596 5.65536L6.66667 9.94825L5.04044 8.32203C4.64992 7.9315 4.01675 7.9315 3.62623 8.32203C3.2357 8.71255 3.2357 9.34572 3.62623 9.73624L5.95956 12.0696C6.1471 12.2571 6.40145 12.3625 6.66667 12.3625C6.93188 12.3625 7.18624 12.2571 7.37377 12.0696L12.3738 7.06957Z"
        fill="#4BB34B"
      />
    </svg>
  );
}

export default CheckBoxIcon;
