import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Admin from './pages/admin';
import Main from './pages/main';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Main />}/>
        <Route path='/admin' element={<Admin />}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
