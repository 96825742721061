import { createGlobalStyle } from 'styled-components'

import RobotoLight from './Roboto-Light.ttf'
import RobotoRegular from './Roboto-Regular.ttf'
import RobotoMedium from './Roboto-Medium.ttf'
import RobotoBold from './Roboto-Bold.ttf'

export default createGlobalStyle`
    @font-face {
        font-family: 'Roboto';
        src: local('Roboto'), local('FontName'), 
        url(${RobotoLight}) format('truetype');
        font-weight: 300;
        font-style: normal;
    }

    @font-face {
        font-family: 'Roboto';
        src: local('Roboto'), local('FontName'), 
        url(${RobotoRegular}) format('truetype');
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: 'Roboto';
        src: local('Roboto'), local('FontName'), 
        url(${RobotoMedium}) format('truetype');
        font-weight: 500;
        font-style: normal;
    }

    @font-face {
        font-family: 'Roboto';
        src: local('Roboto'), local('FontName'), 
        url(${RobotoBold}) format('truetype');
        font-weight: 700;
        font-style: normal;
    }

`