import React from "react";
import styled, { keyframes } from "styled-components";
import { notification } from "src/models/notification";

interface props {
  item: notification;
  index: number
}

const MainNotification = ({ item, index}: props) => {

  return (
    <NotificationWrapper delay={(index + 1) * 20000}>
      <SmileItem>{item.smile}</SmileItem>
      <TextBlock>
        <Title>{item.title}</Title>
        <Description>{item.description}</Description>
      </TextBlock>
    </NotificationWrapper>
  );
};

export default MainNotification;

const show = keyframes`
  0% { top: -75px; }
 5% { top: 20px; }
 90% { top: 20px;}
 100% { top: -75px;}
`;

const NotificationWrapper = styled.div<{delay: any}>`
  background: #ffffff;
  border: 0.5px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.1);
  border-radius: 9.55555px;
  padding: 7.28px 15.29px 7.64px;
  display: flex;
  align-items: center;
  gap: 11.47px;
  max-width: 328px;
  position: fixed;
  top: -100px;
  left: 50%;
  transform: translateX(-50%);
  animation: ${show} 6s linear forwards;
  animation-delay: ${({delay}) => (delay)}ms;
  z-index: 10;

  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;

const SmileItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38.22px;
  height: 38.22px;
  flex-shrink: 0;
  border-radius: 100%;
  background: #EBEDF0;
  border: 0.477778px solid rgba(0, 0, 0, 0.08);
`;

const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #2c2d2e;
`;

const Description = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.002em;
  color: #2c2d2e;
`;
