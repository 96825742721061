import * as React from "react";

function CloseImg(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M0.26363 0.263602C0.615103 -0.0878687 1.18495 -0.0878673 1.53642 0.263605L7.00001 5.72722L12.4636 0.263627C12.8151 -0.0878441 13.385 -0.087843 13.7364 0.26363C14.0879 0.615102 14.0879 1.18495 13.7364 1.53642L8.2728 7.00002L13.7364 12.4636C14.0879 12.8151 14.0879 13.385 13.7364 13.7364C13.3849 14.0879 12.8151 14.0879 12.4636 13.7364L7.00001 8.27281L1.53639 13.7364C1.18492 14.0879 0.615074 14.0879 0.263603 13.7364C-0.0878685 13.3849 -0.0878674 12.8151 0.263605 12.4636L5.72722 7.00001L0.263627 1.53639C-0.0878443 1.18492 -0.0878428 0.615073 0.26363 0.263602Z"
        fill="#2688EB"
      />
    </svg>
  );
}

export default CloseImg;
