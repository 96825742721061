import React, { useEffect, useState } from "react";
import styled from "styled-components";

import AdminTopPanel from "src/components/AdminTopPanel";
import AdminLoanList from "src/components/AdminLoanList";
import NotificationsList from "src/components/NotificationsList";
import { loan } from "src/models/loan";
import { notification } from "src/models/notification";
import axios from "axios";
import AuthForm from "src/components/AuthForm";

const Admin = () => {
  const [isActiveTab, setIsActiveTab] = useState(1);

  const [loanlist, setLoanList] = useState<Array<loan>>([]);

  const [notificationlist, setNotificationList] = useState<Array<notification>>(
    []
  );

  useEffect(() => {
    getLoans();
    getNotifications();
  }, []);

  function getLoans() {
    axios.get("/api/getLoans").then(function (response) {
      setLoanList(response.data);
    });
  }

  function getNotifications() {
    axios
      .get("/api/getNotifications")
      .then(function (response) {
        setNotificationList(response.data);
      });
  }

  const [isLoad, setIsLoad] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)

  const validation = () => {
    setIsSuccess(false);
    setIsError(false);
    setIsLoad(false);

    const newList = loanlist.map((loan) => {
      loan.errorName = loan.name.length < 1;
      loan.errorOfferLink = loan.offerLink.length < 1;
      loan.errorLogoLink = loan.logoLink.length < 1;
      loan.errorBenefits = loan.benefits.length < 1;
      loan.errorPercentMin = loan.percentMin ? false : true;
      loan.errorPercentMax = loan.percentMax ? false : true;
      loan.errorSumMin = loan.sumMin ? false : true;
      loan.errorSumMax = loan.sumMax ? false : true;
      loan.errorDeadline = loan.deadline ? false : true;

      loan.errorItem =
        loan.errorDeadline ||
        loan.errorSumMax ||
        loan.errorSumMin ||
        loan.errorPercentMax ||
        loan.errorPercentMin ||
        loan.errorBenefits ||
        loan.errorLogoLink ||
        loan.errorOfferLink ||
        loan.errorName;

      if (loan.errorItem) {
        setIsLoad(false);
        setIsSuccess(false);
        setIsError(true);
      }

      return loan;
    });

    setLoanList(newList);

    if (!isError) {
      setIsLoad(true);

      if (!axios.post("/api/updateLoans", loanlist)) return;

      if (!axios.post("/api/updateNotifications", notificationlist)) return;

      setIsLoad(false);
      setIsSuccess(true);

      setTimeout(() => {
        setIsSuccess(false)
      }, 6000);
    }
  };

  const tabsContent = [
    {
      id: 1,
      content: <AdminLoanList loanlist={loanlist} setLoanList={setLoanList} />,
    },
    {
      id: 2,
      content: (
        <NotificationsList
          notificationlist={notificationlist}
          setNotificationList={setNotificationList}
        />
      ),
    },
  ];

  const auth = localStorage.getItem("auth");

  if (auth) {
    return (
      <Container>
        <AdminTopPanel
          isActiveTab={isActiveTab}
          setIsActiveTab={setIsActiveTab}
          validation={validation}
          isLoad={isLoad}
          isSendError={isError}
          isSucces={isSuccess}
        />
        {tabsContent.map((tabContent) => {
          return (
            <AdminTabContent
              key={tabContent.id}
              isActiveTab={isActiveTab === tabContent.id}
            >
              {tabContent.content}
            </AdminTabContent>
          );
        })}
      </Container>
    );
  } else {
    return <AuthForm />;
  }
};

export default Admin;

const Container = styled.div`
  width: 962px;
  padding: 0 15px 200px;
  margin: 0 auto;
`;

const AdminTabContent = styled.div<{ isActiveTab: boolean }>`
  display: ${({ isActiveTab }) => (isActiveTab ? "block" : "none")};
`;
