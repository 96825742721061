import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";

import LoadIcon from "../assets/img/svg/loadIcon";
import ErrorIcon from "../assets/img/svg/ErrorIcon";
import SuccessIcon from "../assets/img/svg/SuccessIcon";

export interface AdminTopPanelProps {
  isActiveTab: number;
  setIsActiveTab: (id: number) => void;
  validation: Function;
  isLoad: boolean;
  isSendError: boolean;
  isSucces: boolean;
}

export const AdminTopPanel: React.FC<AdminTopPanelProps> = ({
  isActiveTab,
  setIsActiveTab,
  validation,
  isLoad,
  isSendError,
  isSucces,
}) => {
  const tabs = [
    { id: 1, name: "Займы" },
    { id: 2, name: "Уведомления" },
  ];
  const [show, setShow] = useState(true)

  return (
    <TopPanel>
      <Title>Витрина</Title>
      <Tabs>
        {tabs.map((tab) => {
          return (
            <Tab
              key={tab.id}
              isActive={isActiveTab === tab.id}
              onClick={() => setIsActiveTab(tab.id)}
            >
              {tab.name}
            </Tab>
          );
        })}
      </Tabs>
      <SaveBtnWrapper>
        <Status>
          {isLoad ? (
            <Animateload>
              <LoadIcon />
            </Animateload>
          ) : (
            ""
          )}
          {isSendError ? <ErrorIcon /> : ""}
          {isSucces ? <AnimateSucces><SuccessIcon /></AnimateSucces> : ""}
        </Status>
        <SaveBtn onClick={() => validation()}>Сохранить изменения</SaveBtn>
      </SaveBtnWrapper>
    </TopPanel>
  );
};

export default AdminTopPanel;

const TopPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding-top: 24px;
`;
const Title = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 28px;
  color: #000000;
  width: 228px;
`;
const Tabs = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Tab = styled.div<{ isActive?: boolean }>`
  padding: 6px 16px;
  color: ${({ isActive }) => (isActive ? "#000000" : "#818C99")};
  background: ${({ isActive }) => (isActive ? "rgba(0, 0, 0, 0.04);" : "none")};
  opacity: ${({ isActive }) => (isActive ? "0.72" : "1")};

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2px;
  border-radius: 8px;
  cursor: ${({ isActive }) => (isActive ? "default" : "pointer")};
`;
const SaveBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
const Status = styled.div`
  width: 42px;
  height: 42px;
`;
const rot = keyframes`
    0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
const Animateload = styled.div`
  animation: 1.5s linear 0s normal none infinite running ${rot};
  width: 100%;
  height: 100%;
`;
const fadeIn = keyframes`
    0% {
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
`;
const AnimateSucces = styled.div`
  animation: ${fadeIn} 5s linear forwards;
`;
const SaveBtn = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.01em;
  padding: 6.5px 16px 7.5px;
  color: #ffffff;
  background: #2688eb;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: #105ca8;
  }
`;
