import React, { useEffect, useState } from "react";
import styled from "styled-components";

import AdviceIcon from "../assets/img/svg/AdviceIcon";
import CheckBoxIcon from "../assets/img/svg/CheckBoxIcon";
import { loan } from "src/models/loan";
import axios from "axios";
import { notification } from "src/models/notification";
import MainNotification from "src/components/MainNotification";

function Main() {
  const [loanlist, setLoanList] = useState<Array<loan>>([]);
  const [notificationlist, setNotificationList] = useState<Array<notification>>(
    []
  );

  useEffect(() => {
    getLoans();
    getNotifications();
  }, []);

  function getLoans() {
    axios.get("/api/getLoans").then(function (response) {
      setLoanList(response.data);
    });
  }

  function getNotifications() {
    axios
      .get("/api/getNotifications")
      .then(function (response) {
        setNotificationList(response.data);
      });
  }

  return (
    <Background>
      <Container>
        <Advice>
          <AdviceIcon />
          <AdviceText>
            <b>Совет:</b> Чтобы увеличить вероятность и скорость одобрения
            займа, оставьте анкеты сразу в нескольких компаниях!
          </AdviceText>
        </Advice>
        {notificationlist.map((item, key) => {
          return <MainNotification key={key} index={key} item={item} />;
        })}

        <Divider />
        <CardWrapper>
          {loanlist.map((item, index) => {
            if (item.show)
              return (
                <Card key={index}>
                  <LabelsWrapper>
                    <BenefitLabel color={item.backingColor}>
                      {item.benefits}
                    </BenefitLabel>
                    <CheckBox>
                      <CheckBoxIcon />
                    </CheckBox>
                  </LabelsWrapper>
                  <LogoBlock color={item.backingLogoColor}>
                    <Logo src={`${item.logoLink}`} />
                    <LogoLink href={item.offerLink} />
                  </LogoBlock>
                  <DescriptionBlock>
                    <DescriptionTitle>Сумма</DescriptionTitle>
                    <DescriptionValue>
                      от{" "}
                      {item.sumMin ? item.sumMin.toLocaleString() : item.sumMin}{" "}
                      ₽ <StyleBr />
                      до{" "}
                      {item.sumMax
                        ? item.sumMax.toLocaleString()
                        : item.sumMax}{" "}
                      ₽
                    </DescriptionValue>
                    <DescriptionTitle>Срок/Процент</DescriptionTitle>
                    <DescriptionValue>
                      от {item.percentMin} до {item.percentMax} дн./ до{" "}
                      {item.deadline}%
                    </DescriptionValue>
                  </DescriptionBlock>
                  <CardBtn href={item.offerLink} target="_blank">Получить деньги</CardBtn>
                </Card>
              );
          })}
        </CardWrapper>
      </Container>
    </Background>
  );
}

export default Main;

const Background = styled.div`
  width: 100%;
  min-height: 100vh;
  background: #f5f4f2;
  padding-top: 24px;
`;

const Container = styled.div`
  width: 500px;
  margin: 0 auto;
  padding: 0 16px;
  width: 100%;
  max-width: 1090px;

  @media screen and (max-width: 576px) {
    width: 100%;
    padding: 0px 10px;
  }
`;

const Advice = styled.div`
  display: flex;
  align-items: start;
  gap: 12px;
  padding-bottom: 20px;
  max-width: 328px;
  margin: 0 auto;

  & svg {
    flex-shrink: 0;
  }
`;

const AdviceText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #2c2d2e;

  & b {
    font-weight: 600;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 0.5px;
  background: #d7d8d9;
  margin-bottom: 22px;
`;

const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  @media screen and (max-width: 576px) {
    justify-content: flex-start;
    gap: 10px;
  }
`;

const Card = styled.div`
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 32px;
  width: calc(25% - 15px);
  border: 0.5px solid #d7d8d9;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.075);
  border-radius: 12px;
  padding: 14px 14px 18px;
  background: #fff;
  max-width: 250px;

  @media screen and (max-width: 992px) {
    width: calc(33% - 15px);
    max-width: none;
  }

  @media screen and (max-width: 768px) {
    width: calc(50% - 10px);
    min-width: 139px;
  }

  @media screen and (max-width: 576px) {
    width: calc(50% - 5px);
    min-width: 139px;
    margin-bottom: 14px;
    padding: 10px 10px 9px;
  }

  @media screen and (max-width: 300px) {
    width: 100%;
  }
`;

const LogoBlock = styled.div<{ color: any }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  border-radius: 8px;
  overflow: hidden;
  background: ${({ color }) => (color ? color : "#FFFFFF")};
`;

const LogoLink = styled.a`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const StyleBr = styled.br`
  display: none;

  @media screen and (max-width: 576px) {
    display: block;
  }
`;

const LabelsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const BenefitLabel = styled.div<{ color: string }>`
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.2px;
  color: #ffffff;
  background: ${({ color }) => (color ? color : "#ff3347")};
  border-radius: 10px;

  /* position: absolute;
  top: 7.94px;
  left: 8px; */
  padding: 4px 8px;
  font-size: 12px;

  @media screen and (max-width: 576px) {
    padding: 2px 4px;
    font-size: 10px;
  }
`;

const CheckBox = styled.div`
  /* position: absolute;
  top: 7.94px;
  right: 8px; */
  display: flex;
`;

const Logo = styled.img`
  max-width: 100px;

  @media screen and (max-width: 576px) {
    max-width: 100px;
  }
`;

const DescriptionBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;

  @media screen and (max-width: 576px) {
    margin-top: 7px;
  }
`;

const DescriptionTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #818c99;
  margin-top: 12px;

  &:first-child {
    margin-top: 0;
  }

  @media screen and (max-width: 576px) {
    font-weight: 600;
    font-size: 12px;
    line-height: 26px;
  }
`;

const DescriptionValue = styled.div`
  font-weight: bold;
  font-family: "Roboto";
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #000000;
  margin-top: 4px;

  @media screen and (max-width: 576px) {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.4px;
  }
`;

const CardBtn = styled.a`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.004em;
  color: #ffffff;
  padding: 9px 15px;
  background: #4bb34b;
  border-radius: 8px;
  text-decoration: none;
  transition: all 0.3s;

  &:hover {
    background: #319f31;
  }
`;
