import { useEffect, useRef } from "react";
import styled from "styled-components";

interface tooltipProps {
  opened: boolean;
  onClose: Function;
  children: React.ReactNode;
}

const Tooltip = ({ opened, onClose, children }: tooltipProps) => {
  const tooltipRef = useRef<any>(null);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (e: any) => {
    if (!tooltipRef.current) return;
    if (!tooltipRef.current.contains(e.target)) {
      onClose(false);
    }
  };

  if (!opened) return null;

  return <ColorPopup ref={tooltipRef}>{children}</ColorPopup>;
};

const ColorPopup = styled.div`
  display: "block";
  position: absolute;
  top: 36px;
  left: 4px;
  z-index: 9;
`;

export default Tooltip