import { createGlobalStyle, css } from "styled-components";
import reset from "styled-reset";

const size = {
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1201px",
  xxl: "1499px",
  smMax: "575.98px",
  mdMax: "767.98px",
  lgMax: "991.98px",
  xlMax: "1199.98px",
};

export const light = {
  font: {
    default: "Roboto",
  },

  device: {
    sm: `(min-width: ${size.sm})`,
    md: `(min-width: ${size.md})`,
    lg: `(min-width: ${size.lg})`,
    xl: `(min-width: ${size.xl})`,
    xxl: `(min-width: ${size.xxl})`,
    smMax: `(max-width: ${size.lgMax})`,
    lgMax: `(max-width: ${size.lgMax})`,
    mdMax: `(max-width: ${size.mdMax})`,
    xlMax: `(max-width: ${size.xlMax})`,
    maxXl: `(max-width: ${size.xl})`,
  },
};

export type ThemeType = typeof theme;

export const GlobalStyle = createGlobalStyle`
    ${reset}

    *, *:before, *:after {
        box-sizing: border-box;
    }

    html {
        box-sizing: border-box;
        overflow: overlay;
    }

    body {
        height:100vh; 
        margin:0;
        line-height: 1.5;
        input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
        input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
        input[type="search"]::-webkit-search-decoration,
        input[type="search"]::-webkit-search-cancel-button,
        input[type="search"]::-webkit-search-results-button,
        input[type="search"]::-webkit-search-results-decoration { display: none; }
        font-family: "Roboto";
        background: #F5F4F2;
    }
`;

export const theme = light;
