import { Reorder } from "framer-motion";
import React from "react";
import { NotificationItem } from "./NotificationItem";
import styled from "styled-components";
import NotificationAddBtn from "./NotificationAddBtn";
import { notification } from "src/models/notification";

interface props {
  notificationlist: Array<notification>,
  setNotificationList: any
}

function NotificationsList({ notificationlist, setNotificationList }: props) {

  const mainHandler: Function = (flag: string, id: number, value: string) => {
    const newArr = notificationlist.map((item: any) => {
      if (item.id === id) item[flag] = value;
      return item;
    });

    setNotificationList(newArr);
  };

  const showHandler: Function = (id: number) => {
    const newArr = notificationlist.map((item) => {
      if (item.id === id) item.show = !item.show;

      return item;
    });

    setNotificationList(newArr);
  };

  function randomInteger(min: number, max: number) {
    let rand = min - 0.5 + Math.random() * (max - min + 1);
    return Math.round(rand);
  }

  const addNewNotification: Function = () => {
    const newNotification: notification = {
      id: randomInteger(1, 10000),
      show: true,
      title: "",
      description: "",
      smile: "👍",
      order: notificationlist.length,
    };

    setNotificationList([...notificationlist, newNotification]);
  };

  const removeNotification: Function = (id: number) => {
    setNotificationList(notificationlist.filter((obj) => obj.id !== id));
  };

  const copyNotification: Function = (id: number) => {
    let copyItem = Object.assign({}, notificationlist.find(obj => obj.id === id));

    if (copyItem) {
      copyItem.id = randomInteger(1, 10000);
      setNotificationList([...notificationlist, copyItem])
    }
  };

  return (
    <>
      <NotificationList
        axis="y"
        onReorder={setNotificationList}
        values={notificationlist}
      >
        {notificationlist.map((item) => {
          return (
            <NotificationItem
              key={item.id}
              item={item}
              showHandler={showHandler}
              mainHandler={mainHandler}
              removeNotification={removeNotification}
              copyNotification={copyNotification}
            />
          );
        })}
      </NotificationList>
      <NotificationAddBtn addNewNotification={addNewNotification} />
    </>
  );
}

export default NotificationsList;

const NotificationList = styled(Reorder.Group)`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  gap: 15px;
`;
