import * as React from "react";

function DeleteIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.83711 3H0.75C0.335786 3 0 3.33579 0 3.75C0 4.16421 0.335786 4.5 0.75 4.5H1.29544L2.20208 13.7477C2.25341 14.2714 2.29613 14.7072 2.35919 15.0622C2.42502 15.4328 2.52185 15.7732 2.71166 16.0901C3.00647 16.5823 3.44066 16.976 3.95928 17.2214C4.29319 17.3794 4.64142 17.4426 5.01669 17.4719C5.37612 17.5 5.814 17.5 6.34018 17.5H9.65986C10.186 17.5 10.6239 17.5 10.9833 17.4719C11.3586 17.4426 11.7068 17.3794 12.0408 17.2214C12.5594 16.976 12.9936 16.5823 13.2884 16.0901C13.4782 15.7732 13.575 15.4328 13.6408 15.0622C13.7039 14.7072 13.7466 14.2714 13.798 13.7477L14.7046 4.5H15.25C15.6642 4.5 16 4.16421 16 3.75C16 3.33579 15.6642 3 15.25 3H11.1629C10.8241 1.56638 9.53695 0.5 8 0.5C6.46305 0.5 5.17593 1.56638 4.83711 3ZM6.41825 3H9.58175C9.3009 2.40891 8.69793 2 8 2C7.30207 2 6.6991 2.40891 6.41825 3ZM13.1974 4.5H2.80263L3.69209 13.5725C3.74699 14.1324 3.78449 14.5095 3.83607 14.7999C3.88602 15.0811 3.94015 15.222 3.9985 15.3194C4.14082 15.557 4.35042 15.7471 4.6008 15.8655C4.70344 15.9141 4.84893 15.9542 5.13369 15.9765C5.42768 15.9995 5.80663 16 6.36926 16H9.63078C10.1934 16 10.5724 15.9995 10.8664 15.9765C11.1511 15.9542 11.2966 15.9141 11.3992 15.8655C11.6496 15.7471 11.8592 15.557 12.0015 15.3194C12.0599 15.222 12.114 15.0811 12.164 14.7999C12.2155 14.5095 12.2531 14.1324 12.3079 13.5725L13.1974 4.5ZM10.3122 6.50246C10.725 6.53684 11.0318 6.89934 10.9974 7.31213L10.4974 13.315C10.463 13.7277 10.1005 14.0345 9.68773 14.0001C9.27494 13.9657 8.96819 13.6032 9.00257 13.1905L9.50257 7.18762C9.53695 6.77484 9.89945 6.46808 10.3122 6.50246ZM5.68776 6.50247C6.10055 6.46808 6.46305 6.77484 6.49743 7.18762L6.99743 13.1905C7.03181 13.6032 6.72506 13.9657 6.31227 14.0001C5.89949 14.0345 5.53699 13.7277 5.50261 13.315L5.00261 7.31213C4.96823 6.89935 5.27498 6.53685 5.68776 6.50247Z"
        fill="#99A2AD"
      />
    </svg>
  );
}

export default DeleteIcon