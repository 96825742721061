import { Reorder, useDragControls } from "framer-motion";
import { useState } from "react";
import styled from "styled-components";

import DaDIcon from "../assets/img/svg/DaDIcon";
import DeleteIcon from "../assets/img/svg/DeleteIcon";
import DropdownIcon from "../assets/img/svg/DropdownIcon";
import SaveIcon from "../assets/img/svg/SaveIcon";
import ShowActiveIcon from "../assets/img/svg/ShowActiveIcon";
import ShowUnActiveIcon from "../assets/img/svg/ShowUnActiveIcon";

interface Props {
  item: any;
  showHandler: Function;
  mainHandler: Function;
  removeNotification: Function;
  copyNotification: Function;
}

export const NotificationItem = ({
  item,
  showHandler,
  mainHandler,
  removeNotification,
  copyNotification,
}: Props) => {
  const dragControls = useDragControls();
  const [isActive, setIsActive] = useState(false);
  const smiles = ["👍", "🙌", "👏", "⚡️", "⭐️", "💡", "💰", "💯", "✅"];

  return (
    <Reorder.Item
      key={item.id}
      value={item}
      dragListener={false}
      dragControls={dragControls}
    >
      <Item isShow={item.show}>
        <ItemWrapper>
          <DragAndDropBtn onPointerDown={(event) => dragControls.start(event)}>
            <DaDIcon />
          </DragAndDropBtn>
          <ShowBtn onClick={() => showHandler(item.id)}>
            {item.show ? <ShowActiveIcon /> : <ShowUnActiveIcon />}
          </ShowBtn>
          <TitleInput
            placeholder="Заголовок"
            value={item.title}
            onChange={(e) => {
              mainHandler("title", item.id, e.target.value);
            }}
          />
          <DropdownBtn
            isActive={isActive}
            onClick={() => {
              setIsActive(!isActive);
            }}
          >
            <DropdownIcon />
          </DropdownBtn>
          <SaveItemBtn onClick={() => copyNotification(item.id)}>
            <SaveIcon />
          </SaveItemBtn>
          <DeleteItemBtn onClick={() => removeNotification(item.id)}>
            <DeleteIcon />
          </DeleteItemBtn>
        </ItemWrapper>
        <ItemDropDown isActive={isActive}>
          <Divider />
          <DropContent>
            <Column>
              <ColumnBlockLabel>Выберите смайл</ColumnBlockLabel>
              <SmileWrapper>
                {smiles.map((smile, index) => {
                  return (
                    <SmileItem
                      key={index}
                      isActive={item.smile === smile}
                      onClick={() => {
                        mainHandler("smile", item.id, smile);
                      }}
                    >
                      {smile}
                    </SmileItem>
                  );
                })}
              </SmileWrapper>
            </Column>
            <Column>
              <ColumnBlockLabel>Описание</ColumnBlockLabel>
              <TextArea
                value={item.description}
                placeholder="Текст описания"
                onChange={(e) => {
                  mainHandler("description", item.id, e.target.value);
                }}
              />
            </Column>
          </DropContent>
        </ItemDropDown>
      </Item>
    </Reorder.Item>
  );
};

const Item = styled.div<{ isShow: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background: ${({ isShow }) => (isShow ? "#ffffff" : "#F9F9F9")};
  border: 0.5px solid #d7d8d9;
  border-radius: 12px;
  user-select: none;
`;

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px;
`;

const DragAndDropBtn = styled.div`
  padding: 8px;
  cursor: grab;
`;

const ShowBtn = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  cursor: pointer;
`;

const DefaultInput = styled.input`
  padding: 8px 12px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.007em;
  background: #f2f3f5;
  border: 0.5px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  outline: none;
  width: 100%;

  &:not(:last-child) {
    margin-right: 10px;
  }

  &:last-child {
    margin-right: 18px;
  }
`;

const TitleInput = styled(DefaultInput)``;

const SmileWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const DropdownBtn = styled.div<{ isActive: boolean }>`
  padding: 15px 12px 15.25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  cursor: pointer;
  transition: all 0.3s;
  transform: ${({ isActive }) => (isActive ? "rotate(180deg)" : "none")};
`;

const ItemDropDown = styled.div<{ isActive: boolean }>`
  height: auto;
  max-height: ${({ isActive }) => (isActive ? "250px" : "0px")};
  transition: all 0.3s;
  overflow: hidden;
  width: 100%;
`;

const SaveItemBtn = styled.div`
  padding: 9.5px 10.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2px;
  cursor: pointer;

  & svg path {
    transition: fill 0.3s;
  }

  &:hover svg path {
    fill: rgb(38, 136, 235);
  }
`;

const DeleteItemBtn = styled.div`
  padding: 9.5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & svg path {
    transition: fill 0.3s;
  }

  &:hover svg path {
    fill: red;
  }
`;

const SmileItem = styled.div<{ isActive: boolean }>`
  width: 32px;
  height: 32px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: ${({ isActive }) => (isActive ? "rgb(38 136 235 / 30%)" : "")};
  border: ${({ isActive }) =>
    isActive
      ? "0.5px solid rgb(38 136 235 / 30%)"
      : "0.5px solid rgba(0, 0, 0, 0.12)"};
`;

const TextArea = styled.textarea`
  padding: 8px 12px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.007em;
  background: #f2f3f5;
  border: 0.5px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  outline: none;
  width: 100%;
  resize: none;
  height: 80px;
`;

// Drop
const DropContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 18px 24px 20px;
`;

const Divider = styled.div`
  width: 100%;
  height: 0.5px;
  background: #99a2ad;
`;

const Column = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 6px;
`;

const ColumnBlockLabel = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #818c99;
  gap: 8px;
`;
