import { Reorder, useDragControls } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { TwitterPicker } from "react-color";

import DaDIcon from "../assets/img/svg/DaDIcon";
import DeleteIcon from "../assets/img/svg/DeleteIcon";
import DropdownIcon from "../assets/img/svg/DropdownIcon";
import PreviewIcon from "../assets/img/svg/PreviewIcon";
import SaveIcon from "../assets/img/svg/SaveIcon";
import ShowActiveIcon from "../assets/img/svg/ShowActiveIcon";
import ShowUnActiveIcon from "../assets/img/svg/ShowUnActiveIcon";
import colorBack from "../assets/img/svg/colorBack.svg";
import AddImgIcon from "../assets/img/svg/AddImgIcon";
import CloseImg from "../assets/img/svg/CloseImg";
import axios from "axios";
import Tooltip from "./Tooltip";

interface Props {
  item: any;
  showHandler: Function;
  mainHandler: Function;
  removeLoan: Function;
  copyLoan: Function;
}

export const LoanItem = ({
  item,
  showHandler,
  mainHandler,
  removeLoan,
  copyLoan,
}: Props) => {
  const dragControls = useDragControls();

  const [isActive, setIsActive] = useState(false);
  const [backingColorIsActive, setbackingColorIsActive] = useState(false);
  const [backingLogoColorIsActive, setbackingLogoColorIsActive] =
    useState(false);

  const filePicker = useRef<HTMLInputElement>(null);
  const handleChange = (event: any) => {
    if (!event.target.files) return;

    let formData = new FormData();
    formData.append("image", event.target.files[0]);

    axios
      .post("/api/uploadImg", formData)
      .then(function (response) {
        console.log(response.data);
        mainHandler("logoLink", item.id, response.data);
      });
  };
  const handlePick = () => {
    if (filePicker.current) filePicker.current.click();
  };

  return (
    <Reorder.Item
      key={item.id}
      value={item}
      dragListener={false}
      dragControls={dragControls}
    >
      <Item show={item.show} error={item.errorItem} dropActive={isActive}>
        <ItemWrapper>
          <DragAndDropBtn onPointerDown={(event) => dragControls.start(event)}>
            <DaDIcon />
          </DragAndDropBtn>
          <ShowBtn onClick={() => showHandler(item.id)}>
            {item.show ? <ShowActiveIcon /> : <ShowUnActiveIcon />}
          </ShowBtn>
          <Preview onClick={handlePick}>
            {item.logoLink ? (
              <PreviewImg src={`${item.logoLink}`} />
            ) : (
              <PreviewIcon />
            )}
          </Preview>
          <TitleInput
            placeholder="Заголовок"
            value={item.name}
            onChange={(e) => mainHandler("name", item.id, e.target.value)}
            isError={item.errorName}
          />
          <OfferLinkInput
            placeholder="Ссылка на оффер..."
            value={item.offerLink}
            onChange={(e) => mainHandler("offerLink", item.id, e.target.value)}
            isError={item.errorOfferLink}
          />
          <OfferLogoLinkInput
            placeholder="Ссылка лого оффера..."
            value={item.logoLink}
            onChange={(e) => mainHandler("logoLink", item.id, e.target.value)}
            isError={item.errorLogoLink}
          />
          <DropdownBtn
            isActive={isActive}
            onClick={() => {
              setIsActive(!isActive);
            }}
          >
            <DropdownIcon />
          </DropdownBtn>
          <CopyItemBtn onClick={() => copyLoan(item.id)}>
            <SaveIcon />
          </CopyItemBtn>
          <DeleteItemBtn onClick={() => removeLoan(item.id)}>
            <DeleteIcon />
          </DeleteItemBtn>
        </ItemWrapper>
        <ItemDropDown isActive={isActive}>
          <Divider />
          <DropContent>
            <Row>
              <Column>
                <ColumnBlock>
                  <ColumnBlockLabel>Лого оффера</ColumnBlockLabel>
                  <AddImageBtn onClick={handlePick}>
                    <AddImgIcon />
                    <AddImageText>Выбрать изображение</AddImageText>
                    <ImgInput
                      type="file"
                      onChange={handleChange}
                      accept="image/*, .png, .jpg"
                      ref={filePicker}
                    />
                  </AddImageBtn>
                </ColumnBlock>
              </Column>
              <Column>
                <ColumnBlock>
                  <ColumnBlockLabel>
                    Преимущества <Necessarily>*</Necessarily>
                  </ColumnBlockLabel>
                  <ColumnBlockContent>
                    <DropInput
                      placeholder="0"
                      value={item.benefits}
                      type="text"
                      onChange={(e) =>
                        mainHandler("benefits", item.id, e.target.value)
                      }
                      isError={item.errorBenefits}
                    />
                  </ColumnBlockContent>
                </ColumnBlock>
                <ColumnBlock>
                  <ColumnBlockLabel>Цвет подложки</ColumnBlockLabel>
                  <ColumnBlockContent>
                    <Tooltip
                      opened={backingColorIsActive}
                      onClose={() => {
                        setbackingColorIsActive(false);
                      }}
                    >
                      <TwitterPicker
                        color={item.backingColor}
                        onChangeComplete={(color) =>
                          mainHandler("backingColor", item.id, color.hex)
                        }
                      />
                    </Tooltip>
                    <SubstrateBtn
                      onClick={() => setbackingColorIsActive((v) => !v)}
                    >
                      <ColorCircle color={item.backingColor} />
                      <ColorText>{item.backingColor}</ColorText>
                    </SubstrateBtn>
                  </ColumnBlockContent>
                </ColumnBlock>
              </Column>
              <Column>
                <ColumnBlock>
                  <ColumnBlockLabel>
                    Срок <Necessarily>*</Necessarily>
                  </ColumnBlockLabel>
                  <ColumnBlockContent>
                    от{" "}
                    <DropInput
                      placeholder="минимальный"
                      value={item.percentMin}
                      type="number"
                      onChange={(e) =>
                        mainHandler("percentMin", item.id, e.target.value)
                      }
                      onWheel={(e: any) => e.target.blur()}
                      isError={item.errorPercentMin}
                    />{" "}
                    до{" "}
                    <DropInput
                      placeholder="максимальный"
                      value={item.percentMax}
                      type="number"
                      onChange={(e) =>
                        mainHandler("percentMax", item.id, e.target.value)
                      }
                      isError={item.errorPercentMax}
                      onWheel={(e: any) => e.target.blur()}
                    />
                  </ColumnBlockContent>
                </ColumnBlock>
              </Column>
            </Row>
            <Row>
              <Column>
                <ColumnBlock>
                  <ColumnBlockLabel>
                    Цвет подложи лого (если требуется)
                  </ColumnBlockLabel>
                  <ColumnBlockContent>
                    <SubstrateWrapper>
                      <SubstrateBtn
                        onClick={() =>
                          setbackingLogoColorIsActive(!backingLogoColorIsActive)
                        }
                      >
                        <ColorCircle color={item.backingLogoColor} />
                        <ColorText>
                          {item.backingLogoColor
                            ? item.backingLogoColor
                            : `не выбран`}
                        </ColorText>
                      </SubstrateBtn>
                      <CloseImgBlock
                        show={item.backingLogoColor}
                        onClick={() =>
                          mainHandler("backingLogoColor", item.id, null)
                        }
                      >
                        <CloseImg />
                      </CloseImgBlock>
                    </SubstrateWrapper>

                    <Tooltip
                      opened={backingLogoColorIsActive}
                      onClose={() => setbackingLogoColorIsActive(false)}
                    >
                      <TwitterPicker
                        color={item.backingColor}
                        onChangeComplete={(color) =>
                          mainHandler("backingLogoColor", item.id, color.hex)
                        }
                      />
                    </Tooltip>
                  </ColumnBlockContent>
                </ColumnBlock>
              </Column>
              <Column>
                <ColumnBlock>
                  <ColumnBlockLabel>
                    Сумма <Necessarily>*</Necessarily>
                  </ColumnBlockLabel>
                  <ColumnBlockContent>
                    от{" "}
                    <DropInput
                      placeholder="минимальная"
                      value={item.sumMin}
                      type="number"
                      onChange={(e) =>
                        mainHandler("sumMin", item.id, e.target.value)
                      }
                      isError={item.errorSumMin}
                      onWheel={(e: any) => e.target.blur()}
                    />{" "}
                    до{" "}
                    <DropInput
                      placeholder="максимальная"
                      value={item.sumMax}
                      type="number"
                      onChange={(e) =>
                        mainHandler("sumMax", item.id, e.target.value)
                      }
                      isError={item.errorSumMax}
                      onWheel={(e: any) => e.target.blur()}
                    />
                  </ColumnBlockContent>
                </ColumnBlock>
              </Column>
              <Column>
                <ColumnBlock>
                  <ColumnBlockLabel>
                    Процент <Necessarily>*</Necessarily>
                  </ColumnBlockLabel>
                  <ColumnBlockContent>
                    до{" "}
                    <DropInput
                      placeholder="максимальный"
                      value={item.deadline}
                      type="number"
                      onChange={(e) =>
                        mainHandler("deadline", item.id, e.target.value)
                      }
                      isError={item.errorDeadline}
                      onWheel={(e: any) => e.target.blur()}
                    />
                  </ColumnBlockContent>
                </ColumnBlock>
              </Column>
            </Row>
          </DropContent>
        </ItemDropDown>
      </Item>
    </Reorder.Item>
  );
};

const Item = styled.div<{
  show: boolean;
  error: boolean;
  dropActive: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  transition: all 0.3s;
  background: ${({ show, error, dropActive }) => {
    if (show) {
      if (error && !dropActive) {
        return "#FAEBEB";
      }
      return "#ffffff";
    } else {
      return "#F9F9F9";
    }
  }};
  border: 0.5px solid #d7d8d9;
  border-radius: 12px;
  user-select: none;
  opacity: ${({ show }) => (show ? "1" : "0.7")};
`;

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px;
`;

const DragAndDropBtn = styled.div`
  padding: 8px;
  cursor: grab;
`;

const ShowBtn = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  cursor: pointer;
`;

const Preview = styled.div`
  background: #ebedf0;
  border-radius: 4.5283px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  width: 100px;
  height: 48.5px;
  flex-shrink: 0;
  overflow: hidden;
  padding: 0 5px;
  cursor: pointer;
`;

const PreviewImg = styled.img`
  width: 100%;
`;

const DefaultInput = styled.input`
  padding: 8px 12px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.007em;
  background: #f2f3f5;
  border: 0.5px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  outline: none;
  width: 100%;
  transition: all 0.3s;
  caret-color: #2688eb;

  &:focus {
    border: 0.5px solid #2688eb;
  }

  &:not(:last-child) {
    margin-right: 10px;
  }

  &:last-child {
    margin-right: 18px;
  }
`;

const TitleInput = styled(DefaultInput)<{ isError: boolean }>`
  max-width: 120px;
  background: ${({ isError }) => (isError ? "#FAEBEB;" : "#f2f3f5")};
  border: 0.5px solid
    ${({ isError }) => (isError ? "#E64646;" : "rgba(0, 0, 0, 0.12)")}; ;
`;

const OfferLinkInput = styled(DefaultInput)<{ isError: boolean }>`
  background: ${({ isError }) => (isError ? "#FAEBEB;" : "#f2f3f5")};
  border: 0.5px solid
    ${({ isError }) => (isError ? "#E64646;" : "rgba(0, 0, 0, 0.12)")}; ;
`;

const OfferLogoLinkInput = styled(DefaultInput)<{ isError: boolean }>`
  background: ${({ isError }) => (isError ? "#FAEBEB;" : "#f2f3f5")};
  border: 0.5px solid
    ${({ isError }) => (isError ? "#E64646;" : "rgba(0, 0, 0, 0.12)")}; ;
`;

const DropdownBtn = styled.div<{ isActive: boolean }>`
  padding: 15px 12px 15.25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  cursor: pointer;
  transition: all 0.3s;
  transform: ${({ isActive }) => (isActive ? "rotate(180deg)" : "none")};
`;

const ItemDropDown = styled.div<{ isActive: boolean }>`
  height: auto;
  max-height: ${({ isActive }) => (isActive ? "250px" : "0px")};
  transition: all 0.3s;
  overflow: hidden;
  width: 100%;
`;

const CopyItemBtn = styled.div`
  padding: 9.5px 10.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2px;
  cursor: pointer;

  & svg path {
    transition: fill 0.3s;
  }

  &:hover svg path {
    fill: rgb(38, 136, 235);
  }
`;

const DeleteItemBtn = styled.div`
  padding: 9.5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & svg path {
    transition: fill 0.3s;
  }

  &:hover svg path {
    fill: red;
  }
`;

// Drop
const DropContent = styled.div`
  display: flex;
  gap: 42px;
  padding: 18px 24px 20px;
`;
const Divider = styled.div`
  width: 100%;
  height: 0.5px;
  background: #99a2ad;
`;
const Row = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 50%;
`;
const Column = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 18px;
`;
const ColumnBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;
const ColumnBlockLabel = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #818c99;
  gap: 8px;
`;
const ColumnBlockContent = styled.div`
  position: relative;
`;
const AddImageBtn = styled.div`
  padding: 8px 12px;
  border: 1px solid #2688eb;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 7px;
  cursor: pointer;
`;
const ImgInput = styled.input`
  opacity: 0;
  height: 0;
  width: 0;
  line-height: 0;
  overflow: hidden;
  padding: 0;
  margin: 0;
`;
const AddImageText = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #2688eb;
`;
const Necessarily = styled.span`
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.004em;
  color: #e64646;
`;
const DefaultDropInput = styled.input`
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.007em;
  padding: 8px 12px;
  border: 0.5px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  display: inline-block;
  background: #f2f3f5;
  outline: none;
  width: 186px;
  caret-color: #2688eb;

  &:focus {
    border: 0.5px solid #2688eb;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
const DropInput = styled(DefaultDropInput)<{ isError: boolean }>`
  background: ${({ isError }) => (isError ? "#FAEBEB;" : "#f2f3f5")};
  border: 0.5px solid
    ${({ isError }) => (isError ? "#E64646;" : "rgba(0, 0, 0, 0.12)")}; ;
`;
const SubstrateWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
`;

const CloseImgBlock = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? "flex" : "none")};
`;
const SubstrateBtn = styled.div`
  padding: 6px 12px;
  border: 0.5px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: start;
  display: inline-flex;
  position: relative;
  cursor: pointer;
`;
const ColorCircle = styled.div<{ color: string }>`
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: ${({ color }) => (color ? color : `url("${colorBack}")`)};
`;
const ColorText = styled.div`
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.007em;
  color: #000000;
`;
