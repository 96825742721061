import * as React from "react";

function ShowUnActiveIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.96409 5.50008C9.54988 5.50183 9.21268 5.16746 9.21093 4.75325C9.20918 4.33904 9.54354 4.00184 9.95775 4.00009C12.004 3.99576 14.1048 4.55116 15.8761 5.57766C17.5665 6.55736 19 8.06774 19 10C19 11.003 18.6069 11.9011 18.0013 12.6586C17.7427 12.9821 17.2707 13.0347 16.9472 12.7761C16.6237 12.5174 16.5711 12.0454 16.8298 11.7219C17.2721 11.1686 17.5 10.5911 17.5 10C17.5 8.86976 16.6418 7.75514 15.1239 6.87546C13.5824 5.9821 11.7449 5.49632 9.96409 5.50008Z"
        fill="#99A2AD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.12393 14.4223C5.84342 15.4189 8.03582 16 10 16C11.6051 16 13.3554 15.6123 14.8731 14.9338L15.7197 15.7803C16.0126 16.0732 16.4874 16.0732 16.7803 15.7803C17.0732 15.4874 17.0732 15.0126 16.7803 14.7197L5.28033 3.21967C4.98744 2.92678 4.51256 2.92678 4.21967 3.21967C3.92678 3.51256 3.92678 3.98744 4.21967 4.28033L5.04335 5.10401C4.06903 5.54898 3.17342 6.1239 2.4732 6.81589C1.60643 7.67246 1 8.75167 1 10C1 11.9323 2.43347 13.4426 4.12393 14.4223ZM8.5 10C8.5 10.8284 9.17157 11.5 10 11.5C10.3988 11.5 10.7612 11.3444 11.0299 11.0906L8.90944 8.9701C8.65562 9.23878 8.5 9.60122 8.5 10ZM7 10C7 11.6569 8.34315 13 10 13C10.813 13 11.5505 12.6766 12.0908 12.1514L13.7252 13.7859C12.5236 14.2443 11.2017 14.5 10 14.5C8.29751 14.5 6.36491 13.9874 4.87607 13.1245C3.3582 12.2449 2.5 11.1302 2.5 10C2.5 9.26893 2.85091 8.5515 3.52757 7.8828C4.18791 7.23023 5.121 6.66551 6.18727 6.24793L7.84857 7.90923C7.3234 8.44953 7 9.187 7 10Z"
        fill="#99A2AD"
      />
    </svg>
  );
}

export default ShowUnActiveIcon;
