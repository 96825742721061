import React from "react";
import styled from "styled-components";

import AddIcon from "../assets/img/svg/AddIcon";

interface props {
    addNewNotification: Function
}

function NotificationAddBtn({addNewNotification} : props) {
  return (
    <AddBtn onClick={() => {addNewNotification()}}>
      <AddBtnIcon />
      <BtnText>Добавить уведомление</BtnText>
    </AddBtn>
  );
}

export default NotificationAddBtn;

const AddBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 13.5px 15px;
  border: 1px solid #2688eb;
  border-radius: 12px;
  gap: 6px;
  margin-top: 15px;
  cursor: pointer;
  transition: all .3s;

  &:hover {
    background: #2688eb;
  }

  &:hover svg path {
    fill: #fff;
  }
`;

const AddBtnIcon = styled(AddIcon)`
  transition: all .3s;
`;

const BtnText = styled.div`
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #2688eb;

  ${AddBtn}:hover & {
    color: #fff;
  }
`;
